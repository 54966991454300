<template>
    <div class="searchPage">
        <!-- 搜索栏 -->
         <div class="searchBar">
             <div class="backIcon" @click="$router.go(-1);">
              <SvgIcon iconClass="backUp" class="back"></SvgIcon>
             </div>
             <!-- 搜索框 -->
             <div class="searchBox">
                 <img class="searchIco" src="@/assets/png/ranking.png" />
                 <form action="JavaScript:return true;" class="searchForm">
                    <van-field @keydown.enter="search('')" v-model="searchValue" placeholder="动漫/漫画/角色扮演 50000+" center>
                        <template #button>
                            <div @click.stop="clearInput" v-if="searchValue">
                                <svg-icon class="closeBtn" iconClass="searchClose" />
                            </div>
                            <div v-else></div>
                        </template>
                    </van-field>
                 </form>
             </div>
             <div class="searchBtn" @click.stop="search('')">
                 <span>搜索</span>
             </div>
         </div>
        <!-- 搜索历史 -->
        <div class="searchHistory" v-if="searchHistoryList && searchHistoryList.length > 0">
         <div class="historyTitle flex-between">
          <span>历史记录</span>
          <div @click="cleanHistoryKey">
           <SvgIcon iconClass="clearHistory" class="clearHistory"></SvgIcon>
          </div>
         </div>
            <div class="searchHistoryList">

                <div v-if="isSpread" class="historyBox">
                    <div class="searchHistoryItem" v-for="(item,index) in searchHistoryList" :key="index" @click="historyJump(item)">
                        <div class="searchTitle">
                            <span>{{item}}</span>
                        </div>
<!--                        <div class="closeBtn" @click.stop="delKey(index)">-->
<!--                            <img class="searchIco" src="@/assets/png/searchHistoryClose.png" />-->
<!--                        </div>-->
                    </div>
                </div>

                <div v-else class="historyBox">
                    <div class="searchHistoryItem" v-for="(item,index) in searchHistoryList.slice(0, 8)" :key="index" @click="historyJump(item)">
                        <div class="searchTitle">
                            <span>{{item}}</span>
                        </div>
<!--                        <div class="closeBtn" @click.stop="delKey(index)">-->
<!--                            <img class="searchIco" src="@/assets/png/searchHistoryClose.png" />-->
<!--                        </div>-->
                    </div>
                </div>

                <div class="searchHistoryBottom" v-if="searchHistoryList.length > 8 && !isSpread">
                    <div class="searchHistoryBottomBtn" @click="onSpread">
                        <span>展开全部搜索记录</span>
                    </div>
                    <div class="division"></div>
                </div>
                <div class="searchHistoryBottom" v-if="searchHistoryList.length > 8 && isSpread">
                    <div class="searchHistoryBottomBtn" @click="onSpread">
                        <span>收起全部搜索记录</span>
                    </div>
                    <div class="division"></div>
                </div>


            </div>
        </div>
        <!-- 热门标签 -->
        <div class="searchDiscover" v-if="wordsList && wordsList.tagNames.length > 0">
            <div class="searchDiscoverTitle">
                <span>热门标签</span>
            </div>
            <div class="searchDiscoverList">
                <div class="searchDiscoverItem" v-for="(item,index) in wordsList.tagNames" :key="index" @click="toTagDetailPage(index)">
                    <span>{{item}}</span>
                </div>
            </div>
        </div>
        <!-- 热门搜索 -->
        <div class="hotSearch" v-if="hotVideoList && hotVideoList.length > 0">
            <div class="hotSearchTitle">
                <span>热门推荐</span>
            </div>
            <div class="hotSearchList">
                <VerticleThreeList :videoList="hotVideoList.slice(0,9)" :isAcg="hotVideoList[0].kind!==3"></VerticleThreeList>
            </div>
        </div>
    </div>
</template>

<script>

import {querySearchHotWordsList ,queryHotInfoList} from "@/api/video";
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import {cleanSearchKey, delSearchKey, getSearchKeys} from "@/utils/getConfig";
import SvgIcon from "@/components/SvgIcon";
import { Toast } from "vant";

export default {
  name: "searchPage",
    components:{
        VerticleThreeList,
        SvgIcon
    },
    data(){
      return {
          wordsList: null,
          hotVideoList:[],
          searchValue:"",
          searchHistoryList:[],
          isSpread: false
      }
    },mounted() {
        this.getSearchHotWords();
        this.getHotInfoList();
        this.searchHistoryList = getSearchKeys();
    },methods:{
        clearInput() {
            this.searchValue = "";
        },
        async getSearchHotWords() {
            /**
             * 获取搜索推荐词
             * @type {{pageNumber: number, pageSize: number}}
             */

            let req = {
                pageNumber: 1,
                pageSize: 30,
                kind: 0
            }
            let res = await this.$Api(querySearchHotWordsList, req);
            console.log(res);
            if(res && res.code == 200){
                this.wordsList = res.data;
            }

        },async getHotInfoList() {
            let req = {
                pageNumber: 1,
                pageSize: 30,
            }
            let res = await this.$Api(queryHotInfoList, req);

            if(res && res.code == 200){
                this.hotVideoList = res.data.MediaList;
            }
        },search(keyword){
         if (!keyword && !this.searchValue) return Toast('请输入搜索内容');
          const result = this.wordsList.tagNames.some(item => item === this.searchValue || item === keyword) ? 1 : 0;
          this.$router.push({
                path:"/searchResultPage",
                query:{
                    searchValue: this.searchValue || keyword,
                    isTag: result,
                }
            })
        },delKey(index){
            delSearchKey(index);
            this.searchHistoryList = getSearchKeys();
        },toTagDetailPage(index){
          this.$router.push({
           path:"/searchResultPage",
           query:{
            searchValue: this.wordsList.tagNames[index],
            isTag: 1,
           }
          })
            /**
             *  点击标签跳转标签列表
             */
            // this.$router.push({
            //     path:"/tagsDetailPage",
            //     query:{
            //         id: this.wordsList.tags[index],
            //         kind: this.wordsList.kinds[index],
            //         tagName: this.wordsList.tagNames[index],
            //     }
            // })
        },historyJump(item){
          const result = this.wordsList.tagNames.includes(item) ? 1 : 0;
            this.$router.push({
                path:"/searchResultPage",
                query:{
                    searchValue: item,
                    isTag: result,
                }
            })
        },onSpread(){
            /**
             * 展开全部历史搜索记录
             */
            this.isSpread = !this.isSpread;
        },cleanHistoryKey(){
            /**
             * 清理历史记录
             */
            cleanSearchKey();
            this.searchHistoryList = [];
        }
    }
}
</script>

<style lang="scss" scoped>

    .searchPage{
        height: 100%;
        overflow-y: auto;
        .closeBtn {
            width: 22px;
            height: 22px;
            margin-right: 4px;
            display: inline-block;
            vertical-align: middle;
        }
        .searchForm {
            width: 100%;
        }

        .searchBar{
            padding: 8px 0 9px 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fff;

         .backIcon {
          margin-right: 4px;
           .back {
            width: 20px;
            height: 20px;
           }
         }

            .searchBox{
                display: flex;
                align-items: center;
                width: calc(100% - 24px - 72px);
                height: 36px;
                background: #F5F5F5;
                //background: red;
                border-radius: 10px;
                padding: 0 12px;
                margin-right: 12px;

                .searchIco{
                    width: 17px;
                    height: 17px;
                }

                /deep/ .van-cell{
                    //border-radius: 18px;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    background: #F5F5F5;
                    //background: green;
                    margin-left: 10px;
                    align-items: center;
                }

                /deep/ .van-field__control{
                    color: rgb(0,0,0);
                    font-size: 12px;
                }

                /deep/ .van-field__control::placeholder{
                    color: rgb(153,153,153);
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            .searchBtn{
                width: 50px;
                font-size: 16px;
                font-weight: 900;
                color: #333333;
            }

        }

        .searchHistory{
            background: #F5F5F5;
            //border-bottom: 1px solid #E6E6E6;
            .historyTitle {
             padding: 20px 10px 0 10px;
              span {
               color: #333333;
               font-size: 16px;
               font-weight: 900;
               line-height: 22px;
              }
             .clearHistory {
              width: 20px;
              height: 20px;
              z-index: 9;
             }
            }
            .searchHistoryList{
                padding: 16px 10px 0 10px;

                .historyBox {
                 display: flex;
                 flex-wrap: wrap;
                }

                .searchHistoryItem{
                    padding: 4px 14px;
                    background: #FFFFFF;
                    margin-right: 4px;
                    margin-bottom: 12px;
                    border-radius: 8px;
                    position: relative;
                    white-space: nowrap;
                    .searchTitle{
                        font-size: 12px;
                        color: rgb(153,153,153);
                    }

                    .closeBtn{
                     position: absolute;
                     top: -2px;
                     right: -16px;

                        img{
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }

            .searchHistoryBottom{
                padding: 0 12px;
                .searchHistoryBottomBtn{
                    font-size: 12px;
                    color: rgb(137,137,137);
                    text-align: center;
                }

                .division{
                    width: 100%;
                    height: 1px;
                    background: rgb(230,230,230);
                    margin-top: 6px;
                }
            }
        }

        .searchDiscover{
            background: #F5F5F5;
            color: rgb(0,0,0);
            padding-top: 24px;

            .searchDiscoverTitle{
                font-size: 16px;
                font-weight: 900;
                padding: 0 10px;
            }

            .searchDiscoverList{
                display: flex;
                margin: 16px 10px 0 10px;
                flex-wrap: wrap;

                .searchDiscoverItem{
                  padding: 4px 14px;
                  background: #FFFFFF;
                  margin-right: 4px;
                  margin-bottom: 12px;
                  border-radius: 8px;
                  position: relative;
                  white-space: nowrap;
                  font-size: 12px;
                  color: rgb(153,153,153);
                }
            }
        }

        .hotSearch{
            background: #F5F5F5;
            padding: 24px 10px 0;

            .hotSearchTitle{
                font-size: 16px;
                color: #333333;
                font-weight: 900;
                margin-bottom: 9px;
            }
        }
    }
</style>
